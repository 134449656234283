<template>
	<div class="container">
    <div class="title">
      <span class="inner_title">小区信息设置</span>
    </div>
		<div>
			<div class="item-box">
				<span class="item-left">省：</span>
				<el-select v-model="province_id" @change="handleCity" :disabled='true' placeholder="请选择省">
            <el-option
              v-for="item in provinceList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
			</div>
      <div class="item-box">
				<span class="item-left">市：</span>
				<el-select v-model="city_id" @change="handleZone" :disabled='true' placeholder="请选择市">
          <el-option
            v-for="item in cityList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
			</div>
      <div class="item-box">
				<span class="item-left">区：</span>
				<el-select v-model="zone_id" :disabled='true' placeholder="请选择区县">
          <el-option
            v-for="item in zoneList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
			</div>
      <div xl></div>
			<div class="item-box">
				<span class="item-left">小区名：</span>
				<el-input class="rantHouseName" :disabled='true' v-model="title"  show-word-limit placeholder="请输入标题"></el-input>
			</div>
			<div class="item-box">
				<span class="item-left">小区地址：</span>
				<el-input class="rantHouseName" :disabled='true' v-model="address"  show-word-limit placeholder="请输入标题"></el-input>
			</div>
      <!-- <div class="item-box">
        <span class="item-left">地图打点：</span>
				<span class="input">
					<div class="baidumap" id="allmap" style="width:500px;height:400px" v-loading="loading"></div>
				</span>
      </div> -->
			<div class="item-box">
				<span class="item-left">物业公司：</span>
        <el-input class="rantHouseName" :disabled='true' v-model="property_title"  show-word-limit placeholder=""></el-input>
			</div>
			<!-- <div class="sub-box">
				<el-button class="submitBtn" type="primary" plain @click="submit1">提交</el-button>
			</div> -->
		</div>
	</div>
</template>
<script>
  import provinceData from "../../../util/province.json"
  import {getXiaoQuDetailsFn} from '@/api/xiaoQu.js'
  import {mapMutations} from 'vuex'
  import vm from '@/util/event.js'
	export default {
		props: [],
		data() {
			return {
        id:'',
				title:'',//标题
        address:'',

        loading: true,

        provinceList:[],
        cityList:[],
        zoneList:[],
        province_id:'',
        city_id:'',
        zone_id:'',
        property_title:""
			};
		},
    created() {
      if (this.$route.query.id) {
				this.id = this.$route.query.id;
				this.page = this.$route.query.page;
				this.last_page = this.$route.query.last_page;
				this.getXiaoQuDetail();
			}
      this.handleProvince()
      // this.getWuYeList()
		},
		mounted() {
      this.getXiaoQuDetail()
		},
		methods: {
      ...mapMutations(['setXiaoQuName']),
      //获取小区详情
			getXiaoQuDetail() {
				getXiaoQuDetailsFn({
					id: this.id,
          api_token: localStorage.getItem('token1'),
				}).then(res => {
					this.province_id = res.datas.province_id;
					this.city_id = res.datas.city_id;
					this.zone_id = res.datas.zone_id;
					this.title = res.datas.title;
          this.address = res.datas.address;
          this.property_title = res.datas.property_title;
          this.handleCity(this.province_id)
          this.handleZone(this.city_id)
          // this.$store.commit('setXiaoQuName',res.datas.title)
          // localStorage.setItem('xiaoQuName',res.datas.title)
          // vm.$emit('changeXiaoQuName',res.datas)
				});
			},
      //地址输入地址，地图上自动定位
      // searchMap(){
      //   let self = this
      //   var local = new BMap.LocalSearch(self.map, {      
      //       renderOptions:{map: self.map}      
      //   }); 
      //   let value = self.address
      //   local.search(value);
      // },
      // baiduMap() {
      //   let self = this
      //   self.map = new BMap.Map('allmap', {
      //     enableMapClick: true
      //   }); // 创建地图实例
      //   self.map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
        
      //   var geolocation = new BMap.Geolocation()
      //   geolocation.getCurrentPosition(function(r){
      //     self.loading = false
      //     if (this.getStatus() == BMAP_STATUS_SUCCESS){
      //       self.lng = r.point.lng
      //       self.lat = r.point.lat
      //       self.map.clearOverlays();
      //       self.point = new BMap.Point(self.lng, self.lat); // 创建点坐标
      //       self.marker = new BMap.Marker(self.point);
      //       self.map.addOverlay(self.marker);
      //       self.map.centerAndZoom(self.point, 22); // 初始化地图，设置中心点坐标和地图级别
      //     } else {
      //       console.log('failed'+this.getStatus());
      //     }
      //   })
      //   self.map.addEventListener('click',function(e){
      //     self.lng = e.point.lng
      //     self.lat = e.point.lat
      //     self.map.clearOverlays();
      //     self.point = new BMap.Point(e.point.lng, e.point.lat); 
      //     self.marker = new BMap.Marker(self.point);
      //     self.map.addOverlay(self.marker);
      //   })
      // },
			//提交
			submit1() {
				this.editXiaoQuFn({
          id:this.id,
          api_token:localStorage.getItem('token1'),
          province_id:this.province_id,
          city_id:this.city_id,
          zone_id:this.zone_id,
					title:this.title,
          address:this.address,
          lat:this.lat,
          lng:this.lng,
          property_id:this.property_id
				}).then(res => {
					if (res.code == 200) {
						this.$confirm("提交成功", "提交成功", {
							confirmButtonText: "确定",
							callback: () => {
								this.$router.push({
									path: "/xiaoQuList",
									query: {
										page: this.page,
										last_page: this.last_page
									}
								});
							}
						});
					}
				});
			},
      //处理省份数据
      handleProvince(){
        let self = this
        provinceData.map(function(val,index){
          self.provinceList.push({
            id:val.id,
            name:val.name
          })
        })
      },
      //处理市数据
      handleCity(provinceId){
        let self = this
        self.cityList.length = 0
        self.provinceIdSpace = provinceId
        provinceData.map((val,index) => {
          if (val.id == provinceId){
            if (val.children){
              val.children.map((value,ind) => {
                self.cityList.push({
                  id:value.id,
                  name:value.name
                })
              })
            }
          }
        })
      },
      //处理区数据
      handleZone(cityId){
        var self = this
        self.zoneList.length = 0
        provinceData.map((value1,index1) => {
          if (value1.id == self.provinceIdSpace){
            if (value1.children){
              value1.children.map((value2,index2) => {
                if (value2.id == cityId){
                  if (value2.children){
                    value2.children.map((value3,index3) => {
                      self.zoneList.push({
                        id:value3.id,
                        name:value3.name
                      })
                    })
                  }
                }
              })
            }
          }
        })
      },
		},
	};
</script>
<style scoped>
	@import url("../../../style/detail.css");
</style>
