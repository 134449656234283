import request from '@/api/request.js'

//获取小区详情
export function getXiaoQuDetailsFn (data){
  return request({
    url:'/village/my/information',
    method:'get',
    params:data
  })
}
